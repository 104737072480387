var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import { uploadSaveCode } from "../service/index";
import * as qs from "qs";
import is from "./ua";
import { history } from "../routes";
// @ts-ignore
import alertV2 from "./alertV2";
import { AESEncrypt } from "../lib/crypto";
import { iosAndroid } from "./appOc";
import { judgeHbToken } from "../aggApi/hbApi/util";
export function convertDecimalToPercent(decimal, fixed) {
    if (fixed === void 0) { fixed = 2; }
    if (decimal === null || decimal === undefined || isNaN(Number(decimal))) {
        return "--";
    }
    else if (decimal > 0) {
        return "+" + (decimal * 100).toFixed(fixed) + "%";
    }
    else if (decimal <= 0) {
        return (decimal * 100).toFixed(fixed) + "%";
    }
    return "--";
}
export function convertDebumalToPercent(decimal, fixed) {
    if (fixed === void 0) { fixed = 2; }
    if (decimal === null || decimal === undefined || isNaN(Number(decimal))) {
        return "--";
    }
    else if (decimal >= 0) {
        return "+" + decimal.toFixed(fixed) + "%";
    }
    else if (decimal < 0) {
        return decimal.toFixed(fixed) + "%";
    }
    return "--";
}
export function convertDecimalToPercentNpadd(decimal, fixed) {
    if (fixed === void 0) { fixed = 2; }
    if (decimal === null || decimal === undefined || isNaN(Number(decimal))) {
        return "--";
    }
    else if (decimal > 0) {
        return (decimal * 100).toFixed(fixed) + "%";
    }
    else if (decimal <= 0) {
        return (decimal * 100).toFixed(fixed) + "%";
    }
    return "--";
}
/**
 * 百分数转文本显示
 * @param {*} percent
 */
export function percentToDesc(percent) {
    if (percent === null || percent === undefined || isNaN(Number(percent))) {
        return "--";
    }
    else {
        return percent + "%";
    }
}
//查看pdf
export function toPdf(url) {
    if (isApp) {
        iosAndroid("appInfo", {}, function (res) {
            if (res.isPad && res.version > 2022061500) {
                iosAndroid("padTo", { pdf: url });
            }
            else {
                if (is.iPhone) {
                    window.open(url);
                }
                else {
                    location.href = url;
                }
            }
        });
    }
    else {
        if (is.iPhone) {
            history.push("pdf?file=" + url);
        }
        else {
            history.push("pdf?file=" + url);
        }
    }
}
//一级页面集合 跳转
export function allPush(url, type) {
    if (isApp) {
        if (type == "open") {
            window.open(url);
        }
        else {
            history.push(url);
        }
    }
    else {
        if (type == "open") {
            window.open(url);
        }
        else {
            history.push(url);
        }
    }
}
//是否境外ip
export function isCn() {
    return sessionStorage.getItem("isCn") === "false" &&
        sessionStorage.getItem("isCnMsg")
        ? 0
        : 1;
}
//过滤数据
export function fifterValueUtil(val) {
    if (val) {
        if (val == "--") {
            return "--";
        }
        else {
            return val;
        }
    }
    else {
        if (val == 0) {
            return 0;
        }
        return "--";
    }
}
//过滤数据jia单位
export function fiftervUtil(val, unit) {
    if (val) {
        if (val == "--") {
            return "--";
        }
        else {
            return val + unit;
        }
    }
    else {
        if (val == 0) {
            return 0;
        }
        return "--";
    }
}
/**
 * 统一过滤小数
 * @param {*} val
 */
export function fifterDouble(val, unit) {
    if (val === null || val === undefined || val == "--" || isNaN(Number(val))) {
        return "--";
    }
    var useUnit = unit ? unit : "";
    val = Number(val);
    if (val == 0) {
        return val.toFixed(2) + useUnit;
    }
    if (val > 0 && val < 10) {
        return val.toFixed(2) + useUnit;
    }
    else if (val >= 10 && val < 100) {
        return val.toFixed(1) + useUnit;
    }
    else if (val >= 100) {
        return val.toFixed(0) + useUnit;
    }
    if (val < 0 && val > -10) {
        if (val.toFixed(2) === "-0.00") {
            return "0.00" + useUnit;
        }
        return val.toFixed(2) + useUnit;
    }
    else if (val <= -10 && val > -100) {
        return val.toFixed(1) + useUnit;
    }
    else if (val >= -100) {
        return val.toFixed(0) + useUnit;
    }
    else {
        return val.toFixed(0) + useUnit;
    }
}
export function fifterOnly(val, unit) {
    if (val === null || val === undefined || val == "--" || isNaN(Number(val))) {
        return "--";
    }
    return val.toFixed(unit);
}
//函数
function isWx() {
    return new Promise(function (resolve) {
        var ua = navigator.userAgent.toLowerCase();
        if (/MicroMessenger/i.test(ua)) {
            try {
                Window["wx"].miniProgram.getEnv(function (res) {
                    if (res.miniprogram) {
                        resolve("mini-wx");
                    }
                    else {
                        resolve("wechat");
                    }
                });
            }
            catch (error) {
                resolve("wechat");
            }
        }
        else {
            resolve("wechat");
        }
    });
}
export var isInWx = /MicroMessenger/i.test(navigator.userAgent);
export function upSavePointer(params) {
    return __awaiter(this, void 0, void 0, function () {
        var token, UA, appVersion, equipment, httpData, AESHttpData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    token = localStorage.getItem("token");
                    if (!token) {
                        return [2 /*return*/];
                    }
                    UA = window.navigator.userAgent;
                    appVersion = UA
                        ? UA.split("zhishi Version/")[1]
                            ? UA.split("zhishi Version/")[1]
                            : null
                        : null;
                    equipment = "";
                    if (!isApp) return [3 /*break*/, 1];
                    if (is.iPhone) {
                        equipment = "ios";
                    }
                    else {
                        equipment = "android";
                    }
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, isWx()];
                case 2:
                    equipment = _a.sent();
                    _a.label = 3;
                case 3:
                    httpData = __spreadArrays([
                        // { "name": "appFrom", "value": "app当前渠道 哪里下载的" },
                        { name: "appVersion", value: appVersion },
                        { name: "container", value: equipment },
                        {
                            name: "env",
                            value: process.env.NODE_ENV !== "production" ? "dev" : "pro",
                        }
                    ], params);
                    AESHttpData = AESEncrypt(httpData, "!@#*buried_point");
                    uploadSaveCode({ json: AESHttpData })
                        .success(function (res) { })
                        .start();
                    return [2 /*return*/];
            }
        });
    });
}
export function upSaveDian(path, action, page, params, tag, so, abNo) {
    // return new Promise((resolve) => {
    //     let md = new detect(window.navigator.userAgent);
    //     let token = localStorage.getItem('token')
    //     let isMini = false
    //     if (/MicroMessenger/i.test(navigator.userAgent)) {
    //         try {
    //             window['wx'].miniProgram.getEnv((res: any) => {
    //                 isMini = res.miniprogram
    //             });
    //         } catch (e) {
    //         }
    //     }
    //     const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    //     let queryKey: any = 0
    //     for (const key in query) {
    //         queryKey++
    //     }
    //     queryKey = queryKey ? query : null;
    //     let HttpData = {
    //         device: md.mobile() + ',' + md.phone() + ',' + md.userAgent() + ',' + md.os(),
    //         container: isMini ? 'miniWechat' : isApp ? is.iPhone ? 'ios' : 'android' : 'wechat',
    //         path: path,
    //         action: action,
    //         env: process.env.NODE_ENV !== 'production' ? 'dev' : 'pro',
    //         page: page,
    //         uid: token,
    //         params: params ? params : queryKey,
    //         tag: tag,
    //         so: so ? so : null,   // 是入口来源
    //         abNo: abNo ? abNo : null              //是ab测试批次号
    //     }
    //     for (const key in HttpData) {
    //         if (!HttpData[key]) {
    //             delete HttpData[key]
    //         }
    //     }
    //     upSave(HttpData).success(res => {
    //         resolve(res)
    //     }).start();
    // })
}
export var isApp = window.navigator.userAgent.indexOf("zhishi") != -1;
export var tokenUtil = function () {
    return localStorage.getItem("token");
};
export var nullValue = function (percent) {
    if (percent === null || percent === undefined || isNaN(Number(percent))) {
        return "--";
    }
    else {
        return percent;
    }
};
export var copyToClip = function (content, tips) {
    var ele = document.createElement("input"); //创建一个input标签
    ele.setAttribute("value", content); // 设置改input的value值
    document.body.appendChild(ele); // 将input添加到body
    ele.select(); // 获取input的文本内容
    document.execCommand("copy"); // 执行copy指令
    document.body.removeChild(ele); // 删除input标签
    if (!tips) {
        // 显示复制成功之后的提示文本
        alertV2("<img src='" + require("../assets/images/img-alertV2_ok.png") + "' />\u6587\u5B57\u5DF2\u63D2\u5165\u526A\u5207\u677F", "html");
    }
    else {
        alertV2("<img src='" + require("../assets/images/img-alertV2_ok.png") + "' />" + tips, "html");
    }
};
/**
 * 截取名称长度
 */
export var filterStrLength = function (str, len) {
    if (str && typeof str == "string") {
        if (str.length > 8) {
            return str.substring(0, len) + "...";
        }
        else {
            return str;
        }
    }
    else {
        return null;
    }
};
/**
 * 堆排序
 */
export var arrayKeys = function (arr) {
    var i = 0, len = arr.length, keys = [];
    while (i < len) {
        keys.push(i++);
    }
    return keys;
};
// 判断变量是否为数组
function isArray(arr) {
    return {}.toString.call(arr).match(/^\[[^\s]+\s*([^\s]+)\]$/)[1] == "Array";
}
// 堆排序 order = 'desc' : 'asc'
export var heapSort = function (arr, keys, order) {
    if (!isArray(arr) || !isArray(keys))
        return;
    var order = (order + "").toLowerCase() == "desc" ? order : "asc";
    function changePos(arr, cur, left) {
        var tmp;
        tmp = arr[cur];
        arr[cur] = arr[left];
        arr[left] = tmp;
    }
    function heap(arr, start, end, isMax) {
        var isMax = isMax == undefined ? true : isMax, // 是否最大堆，否为最小堆
        cur = start, // 当前节点的位置
        left = 2 * cur + 1; // 左孩子的位置
        for (; left <= end; cur = left, left = 2 * left + 1) {
            if (left < end &&
                ((isMax && arr[left] < arr[left + 1]) ||
                    (!isMax && arr[left] > arr[left + 1]))) {
                left++;
            }
            if ((isMax && arr[cur] >= arr[left]) ||
                (!isMax && arr[cur] <= arr[left])) {
                break;
            }
            else {
                changePos(keys, cur, left);
                changePos(arr, cur, left);
            }
        }
    }
    return (function () {
        for (var len = arr.length, i = Math.floor(len / 2) - 1; i >= 0; i--) {
            heap(arr, i, len - 1, order == "asc");
        }
        for (i = len - 1; i > 0; i--) {
            changePos(keys, 0, i);
            changePos(arr, 0, i);
            heap(arr, 0, i - 1, order == "asc");
        }
    })();
};
//计算两个日期相隔 多少天
export function computer_A_B_day(timeA, timeB) {
    //1.先判断 是不是同一天；
    var dateA = new Date(timeA);
    var dateB = new Date(timeB);
    if (dateA.getFullYear() == dateB.getFullYear() &&
        dateA.getMonth() == dateB.getMonth() &&
        dateA.getDate() == dateB.getDate()) {
        return 0;
    }
    var Dvalue = Math.abs(timeB - timeA);
    var Kvalue = Dvalue / 86400000;
    var returnDay = Math.floor(Kvalue);
    return returnDay;
}
//计算两个日期相隔 [多少天,天余多少小时，天余多少分钟]
export function computer_A_B_timeArr(timeA, timeB) {
    var dateA = new Date(timeA);
    var dateB = new Date(timeB);
    var Dvalue = Math.abs(timeB - timeA);
    var Kvalue = Dvalue / 86400000;
    var returnDay = Math.floor(Kvalue);
    var returnHours = Math.round(((Kvalue - returnDay) * 86400) / 60 / 60);
    var returnMinutes = Math.round(((Kvalue - returnDay) * 86400) / 60);
    if (dateA.getFullYear() == dateB.getFullYear() &&
        dateA.getMonth() == dateB.getMonth() &&
        dateA.getDate() == dateB.getDate()) {
        if (dateA.getHours() == dateB.getHours()) {
            return [0, 0, returnMinutes];
        }
        else {
            return [0, returnHours, returnMinutes];
        }
    }
    else {
        var dataAStr = dateA.getFullYear() +
            "/" +
            (dateA.getMonth() + 1) +
            "/" +
            dateA.getDate();
        var dateA_C = new Date(dataAStr + " 00:00:00");
        var Kvalue_C = Math.abs(timeB - dateA_C.getTime()) / 86400000;
        var returnDay_C = Math.ceil(Kvalue_C);
        return [returnDay_C, 0, 0];
    }
}
//判断一个变量的书否存在
export function compareHasCont(item) {
    if (!item) {
        return false;
    }
    if (item.constructor === Array) {
        return item.length > 0;
    }
    if (item.constructor === Object) {
        return JSON.stringify(item) != "{}";
    }
    if (item.constructor === String) {
        return true;
    }
    if (item.constructor === Number) {
        return true;
    }
}
export function replaceAll(str, reallyDo, replaceWith, ignoreCase) {
    if (!RegExp.prototype.isPrototypeOf(reallyDo)) {
        return str.replace(new RegExp(reallyDo, ignoreCase ? "gi" : "g"), replaceWith);
    }
    else {
        return str.replace(reallyDo, replaceWith);
    }
}
//mysql datetime 转换 yyyy/mm/dd hh:mm:ss
export function timeFormatter(time) {
    var date = time.substr(0, 10); //年月日
    var hours = time.substring(11, 13);
    var minutes = time.substring(14, 16);
    var seconds = time.substring(17, 19);
    var timeFlag = date + " " + hours + ":" + minutes + ":" + seconds;
    timeFlag = timeFlag.replace(/-/g, "/");
    timeFlag = new Date(timeFlag);
    timeFlag = new Date(timeFlag.getTime() + 8 * 3600 * 1000);
    timeFlag =
        timeFlag.getFullYear() +
            "/" +
            (timeFlag.getMonth() + 1 < 10
                ? "0" + (timeFlag.getMonth() + 1)
                : timeFlag.getMonth() + 1) +
            "/" +
            (timeFlag.getDate() < 10 ? "0" + timeFlag.getDate() : timeFlag.getDate()) +
            " " +
            timeFlag.getHours() +
            ":" +
            timeFlag.getMinutes() +
            ":" +
            (timeFlag.getSeconds() < 10
                ? "0" + timeFlag.getSeconds()
                : timeFlag.getSeconds());
    return timeFlag;
}
export var computerUnit = function (item) {
    var item_copy = Math.round(item);
    var lengthItem = String(item_copy).length;
    if (lengthItem > 11 || lengthItem == 11) {
        return (item_copy / 100000000).toFixed(0) + "亿";
    }
    else if (lengthItem > 10 || lengthItem == 10) {
        return (item_copy / 100000000).toFixed(1) + "亿";
    }
    else if (lengthItem > 9 || lengthItem == 9) {
        return (item_copy / 100000000).toFixed(2) + "亿";
    }
    else if ((lengthItem < 9 && lengthItem > 7) || lengthItem == 7) {
        return (item_copy / 10000).toFixed(0) + "万";
    }
    else if ((lengthItem < 7 && lengthItem > 6) || lengthItem == 6) {
        return (item_copy / 10000).toFixed(1) + "万";
    }
    else if ((lengthItem < 6 && lengthItem > 5) || lengthItem == 5) {
        return (item_copy / 10000).toFixed(2) + "万";
    }
    else if (lengthItem < 5 && lengthItem > 0) {
        return item.toFixed(0) + "元";
    }
    else {
        return 0;
    }
};
var stockType;
(function (stockType) {
    stockType["index_hk_hs"] = "index_hk_hs";
    stockType["hk"] = "hk";
    stockType["index_gn"] = "index_gn";
    stockType["capital"] = "capital";
    stockType["index_hs"] = "index_hs";
    stockType["index_hy"] = "index_hy";
    stockType["fund_outside"] = "fund_outside";
    stockType["fund_onside"] = "fund_onside";
    stockType["fund_lof"] = "fund_lof";
})(stockType || (stockType = {}));
export var routeToNewPage = function (code, type) {
    switch (type) {
        case stockType.capital:
            history.push("/stock?stockCode=" + code);
            break;
        case stockType.hk:
            history.push("/stockHk?stockCode=" + code);
            break;
        case stockType.index_hs:
            history.push("/indexStock?stockCode=" + code);
            break;
        case stockType.index_hy:
            history.push("/indexStock?stockCode=" + code);
            break;
        case stockType.fund_outside:
            history.push("/fundDetails?code=" + code);
            break;
        case stockType.fund_onside:
            history.push("/fundDetails?code=" + code);
            break;
        case stockType.fund_lof:
            history.push("/fundDetails?code=" + code);
            break;
        case stockType.index_hk_hs:
            history.push("/indexStock?stockCode=" + code);
            break;
    }
};
export function listFindeItem(Data, keyName, keyValue) {
    return Data
        ? Data.find(function (item, index) {
            return item[keyName] == keyValue;
        })
        : null;
}
/**
 * 获取数组中某个item
 * @param {*} key字段名  keyValue字段值
 */
export function listFindItem(List, key, keyValue, isFindIndex) {
    if (compareHasCont(List)) {
        if (isFindIndex) {
            var select = List.findIndex(function (item) {
                return item[key] == keyValue;
            });
            return select;
        }
        else {
            var select = List.find(function (item) {
                return item[key] == keyValue;
            });
            return select ? select : null;
        }
    }
    else {
        return null;
    }
}
/**
 * 获取数组中某个item
 * @param {*} key字段名  keyValue字段值
 */
export function handleValueBoo(value) {
    if (value || value === 0) {
        return true;
    }
    else {
        return false;
    }
}
/**
 *
 * @param 计算金额
 * @param [value]: 数值  [str]:尾部添加的符号 disUnit 是否删除正号
 */
export function computerAmount(item) {
    if (!item && item !== 0) {
        return [];
    }
    var renderValue = [];
    var boo = item >= 0 ? true : false;
    var item_copy = Math.round(Math.abs(Number(item)));
    var lengthItem = String(item_copy).length;
    if (lengthItem > 15 || lengthItem == 15) {
        renderValue = [(item_copy / 1000000000000).toFixed(0), "万亿"];
    }
    else if (lengthItem > 14 || lengthItem == 14) {
        renderValue = [(item_copy / 1000000000000).toFixed(1), "万亿"];
    }
    else if (lengthItem > 13 || lengthItem == 13) {
        renderValue = [(item_copy / 1000000000000).toFixed(2), "万亿"];
    }
    else if (lengthItem > 11 || lengthItem == 11) {
        renderValue = [(item_copy / 100000000).toFixed(0), "亿"];
    }
    else if (lengthItem > 10 || lengthItem == 10) {
        renderValue = [(item_copy / 100000000).toFixed(1), "亿"];
    }
    else if (lengthItem > 9 || lengthItem == 9) {
        renderValue = [(item_copy / 100000000).toFixed(2), "亿"];
    }
    else if ((lengthItem < 9 && lengthItem > 7) || lengthItem == 7) {
        renderValue = [(item_copy / 10000).toFixed(0), "万"];
    }
    else if ((lengthItem < 7 && lengthItem > 6) || lengthItem == 6) {
        renderValue = [(item_copy / 10000).toFixed(1), "万"];
    }
    else if ((lengthItem < 6 && lengthItem > 5) || lengthItem == 5) {
        renderValue = [(item_copy / 10000).toFixed(2), "万"];
    }
    else if (lengthItem < 5 && lengthItem > 0) {
        renderValue = [item_copy.toFixed(0), ""];
    }
    else {
        renderValue = [];
    }
    return boo ? renderValue : ["-" + renderValue[0], renderValue[1]];
}
/**
 *
 * @param num 传入数字
 * @return 返回一个两项的一维数组，包括单位和转换
 */
export var getAmountArr = function (num) {
    //传入非数字
    if (typeof num !== "number" || isNaN(num))
        return {};
    var lengthItem = String(Math.floor(num)).length;
    if (lengthItem >= 13) {
        return ["万亿", 1000000000000];
    }
    else if (lengthItem >= 9) {
        return ["亿", 100000000];
    }
    else if (lengthItem >= 5) {
        return ["万", 10000];
    }
    else {
        return ["元", 1];
    }
};
/**
 * 计算UI图的rem值
 * @param {*} num ui图的数值
 */
export function computerUI_rem(num) {
    return ((num * 100) / 750).toFixed(4) + "vw";
}
/**
 * 计算季度
 * @param bar 是否添加斜杠 default true
 */
export function computerQuarter(str, bar) {
    if (bar === void 0) { bar = true; }
    var year = str.substring(0, 4);
    var temStr = str.substring(4);
    var quarter;
    switch (temStr) {
        case "0331":
            quarter = "Q1";
            break;
        case "0630":
            quarter = "Q2";
            break;
        case "0930":
            quarter = "Q3";
            break;
        case "1231":
            quarter = "Q4";
            break;
        default:
            quarter = undefined;
            break;
    }
    if (!quarter) {
        return year;
    }
    var consult = bar ? year + "-" + quarter : year + quarter;
    return consult;
}
/**
 * 保留两位小数
 *
 */
export function computerDecimals(num) {
    if (num) {
        return parseFloat(parseFloat(num).toFixed(2));
    }
    else {
        return num;
    }
}
/**判断标题 */
export var CheeseTitle = judgeHbToken() ? "芝士工具" : "芝士财富";
export var getVeryHold = function (quarter) {
    if (typeof quarter === "string" && quarter.length === 8) {
        var str1 = quarter.slice(0, 4);
        var str2 = quarter.slice(4, 6);
        var str3 = quarter.slice(6, 8);
        return [str1, str2, str3].join("-");
    }
    return computerQuarter(quarter);
};
export var getApiDate = function () {
    var hours = new Date().getHours();
    return ((hours < 15 && hours >= 9) || (hours == 15 && new Date().getMinutes() <= 15));
};
// captialOrIndex完整路由
export var captialOrIndexToRoute = function (capitalOrIndex, code) {
    if (capitalOrIndex == "capital") {
        return "/stock?stockCode=" + code;
    }
    else if (capitalOrIndex == "index_hs") {
        return "/indexStock?stockCode=" + code;
    }
    else if (capitalOrIndex == "index_hy") {
        return "/indexStock?stockCode=" + code;
    }
    else if (capitalOrIndex == "index_gn") {
        return "/indexStock?stockCode=" + code;
    }
    else if (capitalOrIndex == "index_hk_hs") {
        return "/indexStock?stockCode=" + code;
    }
    else if (capitalOrIndex == "fund_onside") {
        return "/fundDetails?code=" + code;
    }
    else if (capitalOrIndex == "fund_outside") {
        return "/fundDetails?code=" + code;
    }
    else if (capitalOrIndex == "fund_lof") {
        return "/fundDetails?code=" + code;
    }
    else if (capitalOrIndex == "hk") {
        return "/stockHK?stockCode=" + code;
    }
};
/**
 * 根据证券详情页的路由 获取 该证券 的 capitalOrIndex
 * @returns
 */
export var getCapitalOrIndex = function () {
    var query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
    var path = history.location.pathname;
    var code;
    if (query.code != undefined) {
        code = query.code;
    }
    else {
        code = query.stockCode;
    }
    var type = query.type;
    if (path == "/stock") {
        return "capital";
    }
    if (path == "/indexStock") {
        if (code.substring(code.length - 4, code.length) == ".ZSI") {
            return "index_hy";
        }
        else if (code.substring(code.length - 3, code.length) == ".WI") {
            return "index_gn";
        }
        else if (code.substring(code.length - 3, code.length) == ".HI") {
            return "index_hk_hs";
        }
        else {
            return "index_hs";
        }
    }
    if (path == "/stockHK") {
        return "hk";
    }
    if (path == "/fundDetails") {
        if (type && type == "fund_onside") {
            return "fund_onside";
        }
        if (type && type == "fund_lof") {
            return "fund_lof";
        }
        if (code.substring(code.length - 3, code.length) == ".OF") {
            return "fund_outside";
        }
        return "fund_onside";
    }
};
export function computerTableAmount(value) {
    return handleValueBoo(value)
        ? computerAmount(value)[0] + computerAmount(value)[1]
        : "--";
}
export function computerPriceStr(value, str, disUnit) {
    if (Number(value) >= 0) {
        return str
            ? disUnit
                ? value + str
                : "+" + value + str
            : disUnit
                ? value
                : "+" + value;
    }
    else {
        return str ? value + str : value;
    }
}
export function classNames() {
    var classes = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        classes[_i] = arguments[_i];
    }
    return classes.filter(Boolean).join(" ");
}
//黑夜模式及白天模式
export function lightOrDark() {
    if (document.documentElement.className == "light") {
        return true;
    }
    else {
        return false;
    }
}
export function selectCapitalOrIndex(capitalOrIndex, code) {
    var selecter = "";
    if ((capitalOrIndex == "capital" && code.substring(0, 3) == "600") ||
        code.substring(0, 3) == "601" ||
        code.substring(0, 3) == "603" ||
        code.substring(0, 3) == "605") {
        selecter = "沪";
    }
    if ((capitalOrIndex == "capital" && code.substring(0, 3) == "000") ||
        code.substring(0, 3) == "001" ||
        code.substring(0, 3) == "002" ||
        code.substring(0, 3) == "003") {
        selecter = "深";
    }
    if ((capitalOrIndex == "capital" && code.substring(0, 3) == "688") ||
        code.substring(0, 3) == "689") {
        selecter = "科";
    }
    if ((capitalOrIndex == "capital" && code.substring(0, 3) == "300") ||
        code.substring(0, 3) == "301") {
        selecter = "创";
    }
    if (capitalOrIndex == "capital" &&
        code.includes(".") &&
        code.split(".")[1] == "BJ") {
        selecter = "京";
    }
    if (capitalOrIndex.substring(0, 5) == "index") {
        selecter = "指";
    }
    if (code.includes(".") && code.split(".")[1] == "HK") {
        selecter = "港";
    }
    if (capitalOrIndex == "fund_onside") {
        selecter = "ETF";
    }
    if (capitalOrIndex == "fund_lof") {
        selecter = "LOF";
    }
    return selecter;
}
export function computerColor(value) {
    if (Number(value) > 0) {
        return "text-red";
    }
    else if (Number(value) === 0) {
        return "text-gray_1";
    }
    else {
        return "text-green";
    }
}
/**
 * 接口所需的时间戳
 */
export function generateT() {
    // 1. 获取当前时间戳字符串，长度应为13位（毫秒级时间戳）
    var timestamp = Date.now().toString().substring(0, 13);
    // 2. 获取时间戳的最后一位数字，作为插入位置的基准
    var position = parseInt(timestamp.charAt(timestamp.length - 1), 10);
    // 3. 获取时间戳中倒数第4位的数字，作为要插入的数字
    //    索引为9，因为索引从0开始，倒数第4位即索引为 length - 4 = 13 - 4 = 9
    var digit = timestamp.charAt(9);
    // 4. 如果位置为0，则将其设置为2，避免插入位置为0的情况
    if (position === 0) {
        position = 2;
    }
    // 5. 验证插入位置是否在有效范围内（1到13之间）
    if (position < 1 || position > 13) {
        throw new Error("Invalid position derived from timestamp's last digit");
    }
    // 6. 计算插入索引（将位置转换为零基索引）
    var insertIndex = position - 1;
    // 7. 在指定位置插入数字，将 'digit' 插入到 'timestamp' 中的 'insertIndex' 位置
    var t = timestamp.slice(0, insertIndex) + // 插入点之前的子字符串
        digit + // 要插入的数字
        timestamp.slice(insertIndex); // 插入点之后的子字符串
    // 8. 确保生成的字符串长度为14位
    if (t.length !== 14) {
        throw new Error("Generated 't' does not have length 14");
    }
    // 9. 返回生成的 't' 参数
    return t;
}
